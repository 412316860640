import http from "@/helpers/http";
import qs from "qs";
import { packFormUrl } from "@/helpers/utils";
import { pack } from "@/helpers/utils";
const service = "faUser/";

export function getCustomerList(form) {
  return http.request({
    url: "/SYTdknLasJ.php/user/user/index",
    method: "get",
    params: {
      _: Date.now(),
      ...form,
    },
  });
}

export function editCustomer(form, token) {
  return http.request({
    url: `/SYTdknLasJ.php/user/user/edit/ids/${form.id}`,
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      __token__: token,
      ...packFormUrl(form),
    }),
    params: {
      dialog: 1,
    },
  });
}


export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}
