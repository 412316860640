import http from "@/helpers/http";
import qs from "qs";

export function getCouponList(form) {
  delete form.limit;
  return http.request({
    url: "/faUnidrinkCoupon/listByPage",
    method: "POST",
    params: {
      page: form.currPage,
      pageSize: form.pageSize,
    },
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function addCoupon(form) {
  return http.request({
    url: "/faUnidrinkCoupon/save",
    method: "POST",
    data: {
      body: {
        ...form,
        shopId: form.shopId.join(","),
        starttime: Math.floor(form.starttime.getTime() / 1000),
        endtime: Math.floor(form.endtime.getTime() / 1000),
      },
    },
  });
}

export function editCoupon(form) {
  return http.request({
    url: "/faUnidrinkCoupon/updateById",
    method: "POST",
    data: {
      body: {
        ...form,
        shopId: form.shopId.join(","),
        starttime: Math.floor(form.starttime.getTime() / 1000),
        endtime: Math.floor(form.endtime.getTime() / 1000),
      },
    },
  });
}

export function toggleCoupon(id, status) {
  return http.request({
    url: "/faUnidrinkCoupon/updateById",
    method: "POST",
    data: {
      body: {
        id,
        switchFlag: status + "",
      },
    },
  });
  // return http.request({
  //   url: "/SYTdknLasJ.php/unidrink/coupon/multi",
  //   method: "POST",
  //   headers: { "content-type": "application/x-www-form-urlencoded" },
  //   data: qs.stringify({
  //     ids: id,
  //     params: `switch=${status}`,
  //   }),
  // });
}

export function deleteCoupon(id) {
  return http.request({
    url: "/faUnidrinkCoupon/deleteByIds",
    method: "POST",
    data: {
      body: {
        ids: [id],
      },
    },
  });
}

export function dragAdvert(ids, changeid) {
  return http.request({
    url: "/SYTdknLasJ.php/ajax/weigh",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      ids,
      changeid,
      field: "weigh",
      orderway: "desc",
      table: "unidrink_ads",
      pk: "id",
    }),
  });
}

export function distribute(form) {
  console.log(form);
  return http.request({
    url: "/faUnidrinkCouponUser/save",
    method: "POST",
    data: {
      body: {
        ...form,
      },
    },
  });
}
